import React from 'react';
import { useSelector } from 'react-redux';
import {
  selectPermissions
} from '../redux/loginSlice';

import OperatorHome from './OperatorHome';
import AdminHome from './AdminHome';

const Home = () => {
  const permissions = useSelector(selectPermissions);
  // const permissions = localStorage.getItem('spg-calendar-permissions');

  return (
    <React.Fragment>
            <iframe  src="https://airtable.com/embed/appYAxBCgDddMaTeh/pagh7bkXIfkfdzeAL/form" height={700}></iframe>
    </React.Fragment>
  )
}

export default Home;
